// import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import React from "react"
import Seo from "../../components/seo"

const ArtistsIndexPage = () => {
 


  return (
    <Layout >
      <Seo title="Talman artists" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> artists</h1>

        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>

        <section id="signature" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Signature <a className="hash-link" href="#signature">#</a>
          </h2>
        </section>

        <section id="ibanez" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Ibanez artists <a className="hash-link" href="#ibanez">#</a>
          </h2>
        </section>

        <section id="notable-players" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Notable players <a className="hash-link" href="#notable-players">#</a>
          </h2>
        </section>

        
      </div>
    </Layout>
  )
}


export default ArtistsIndexPage